@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&family=Maven+Pro:wght@400..900&family=Quicksand:wght@300..700&display=swap');
body {
  background-color: #091013;
  font-family: "Quicksand", sans-serif;
  width: 100vw;
}

.navigation {
  background-color: rgb(24,15,10);
}

.hero {
  height: 80vh;
  display: flex;
  background: rgb(118,42,3) bottom;
  background: radial-gradient(ellipse 60% 65% at 50% 90%, rgba(118,42,3,1) 10%, rgba(24,15,10,1) 100%);
  overflow: hidden;
}
.hero-left {
  width: 40%;
  margin-top: 10rem;
  margin-left: 5vw;
  z-index: 10;
}
.hero-left img {
  max-width: 80%;
  margin-left: 10%;
  margin-top: 10%;
  max-height: 400px;
}
.hero-right {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: translate(80px, 60px);
}
.hero-right img {
  width: 100%;
  max-width: 750px;
}
@media (min-width: 1440px) {
  .hero-right img {
    width: 100%;
    max-width: 1100px;
  }
}
.logo-png {
  position: absolute;
  left: 2vw;
  top: 2vh;
  height: 7vh;
}
@media (min-width: 1920px) {
  .hero {
    justify-content: right;
  }
  .hero-left img {
    margin-top: 0;
  }
}
.hero-text {
  font-family: "Maven Pro", sans-serif;
  font-weight: 700;
  color: white;
  font-size: 5rem;
  white-space: nowrap;
}
.hero-text-small {
  color: white;
  font-family: "Maven Pro", sans-serif;
  font-size: 1.1rem;
  width: 80%;
  max-width: 600px;
  line-height: 1.4;
  margin-top: 3rem;
}
.contact-button {
  color: white;
  margin-top: 3rem;
  font-family: "Maven Pro", sans-serif;
  background-color: #F03818;
  font-weight: 700;
  font-size: 1.2rem;
  padding: 1.5rem 3rem 1.5rem 3rem;
  width: fit-content;
  border-radius: 2rem;
  cursor: pointer;

  span {
    margin-left: 1rem;
  }
}


.navigation {
  font-family: "Maven Pro", sans-serif;
  height: 5vh;
}

.nav-container {
  max-width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.logo {
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: -0.3px;
  font-family: "Maven Pro", sans-serif;
  margin-left: 5px;
}
.desktop-menu {
  display: flex;
}
.mobile-menu {
  display: none;
}

.nav-items {
  margin-right: 3rem;
  display: flex;
  align-items: center;
}
.nav-item {
  color: white;
  text-decoration: none;
  font-family: "Maven Pro", sans-serif;
  font-size: 1.5rem;
  margin-left: 3rem;
  background: linear-gradient(currentColor 0 0) 
    bottom left/
    var(--underline-width, 0%) 1px
    no-repeat;
  display: inline-block;
  transition: background-size 300ms;
}
.nav-item:hover {
  --underline-width: 100%;
}

.language-dropdown {
  position: relative;
}
.language-dropdown.mobile {
  margin: 1rem 0;
}
.language-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  font-family: "Maven Pro", sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  justify-content: flex-start;
}
.language-button span {
  margin: 0 0.5rem;
}
.language-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #6c1505;
  border-radius: 4px;
  padding: 0.5rem 0;
  z-index: 10;
}
.language-menu li {
  list-style-type: none;
  font-family: "Maven Pro", sans-serif;
}
.language-menu button {
  width: 100%;
  text-align: left;
  padding: 1rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-family: "Maven Pro", serif;
  font-size: 1em;
}
.language-menu button:hover {
  background-color: #F03818;
}

@media (min-width: 768px) {
  .desktop-menu {
    display: flex;
  }
  .mobile-menu-button {
    display: none;
  }
  .mobile-menu {
    display: none;
  }
  .navigation {
    padding: 2rem 1rem 2rem 1rem;
  }
}



.products-div {
  background-color: #091013;
  display: flex;
  flex-direction: column;
  min-height: 15vh;
}



.products-head-div {
  display: flex;
  flex-direction: column;
  margin-top: 8em;
  margin-bottom: 3em;
  margin-left: 5vw;
}
.products-head {
  color: #f8fcff;
  font-family: "Maven Pro", sans-serif;
  font-size: 4em;
  line-height: 1.5;
  letter-spacing: 1px;
}
.products-head-small {
  color: #F03818;
  font-family: "Maven Pro", sans-serif;
  font-size: 1.1rem;
  font-weight: 900;
}

.carousel-parent {
  min-height: 70vh;
}
.carousel-container {
  width: 100%;
  max-width: 90vw;
  margin: 2em auto;
  position: relative;
  overflow: hidden;
  border-radius: 5em;
  padding-bottom: 2em;
}
.carousel-images {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0 3rem 0;
}
.carousel-image {
  transition: all 0.3s ease;
  margin: 1em;
  transition: opacity 0.3s ease-in-out;
}
.carousel-image.center {
  width: 25vw;
  height: 25vw;
  z-index: 10;
}
.carousel-image.adjacent {
  width: 20vw;
  height: 20vw;
  z-index: 5;
  opacity: 0.8;
}
.carousel-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
  border-radius: 1em;
  box-shadow: rgba(255, 255, 255, 0.15) 0px 0px 100px 0px;
}
.carousel-text-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carousel-text {
  text-align: center;
  margin-top: -3rem;
  font-family: "Maven Pro", sans-serif;
  color: #F8FCFF;
  font-size: 1.5rem;
  font-weight: bolder;
}
.carousel-text .si-text {
  font-size: 0.85em;
  font-weight: 100;
  margin-left: 0.5em;
}
.carousel-text-info {
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;
  font-size: 1.1rem;
  line-height: 1.4;
  font-family: "Maven Pro", sans-serif;
  color: #dde5ed;
}
.carousel-text-info.ingredients {
  font-size: 0.95em;
  margin-top: 2em;
  padding-left: 5em;
  padding-right: 5em;
  max-width: 50vw;
  margin-bottom: 8vh;
}
.carousel-text-info .bi-text {
  font-style: italic;
  text-decoration: underline;
}
.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color:#F8FCFF;
}
.carousel-button.prev {
  left: 0;
}
.carousel-button.next {
  right: 0;
}
.products-text-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50vw;
  margin-left: 5vw;
  color: white;
  font-family: "Maven Pro", sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.4;
}
.products-text-div span {
  margin-bottom: 2em;
}




.contact-div {
  background-color: #091013;
  display: flex;
  flex-direction: column;
  margin-top: 8em;
  margin-bottom: 8em;
  margin-left: 5vw;
  margin-right: 5vw;
}
.contact-head {
  font-family: "Maven Pro", sans-serif;
  font-size: 4em;
  line-height: 1.5;
  letter-spacing: 1px;
  color: white;
}
.contact-head-small {
  color: #F03818;
  font-family: "Maven Pro", sans-serif;
  font-size: 1.1rem;
  font-weight: 900;
}
.contact-items {
  margin-top: 2em;
}
.contact-item {
  font-family: "Maven Pro", sans-serif;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1em;
  font-size: 1.1rem;
  align-items: center;
  color: white;
}
.contact-link {
  color: white;
  text-decoration: none;
  margin-left: 1em;
}
.contact-link:visited {
  color: white;
}
.contact-link:hover, .contact-link:active {
  color: white;
  text-decoration: none;
}
.contact-item-multiline {
  align-items: flex-start;
}
.contact-item-col {
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  span {
    margin-left: 1em;
  }
}



.copyright-div {
  display: flex;
  justify-content: center;
  margin: 1em;
  font-size: 0.95em;
  color: #ffffff6e;
}
.logo-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position:absolute;
  z-index: -5;
  width: 100%;
  padding-top: 0.3rem;
}
.mobile-menu-toggle {
  display: none;
}



@media screen and (max-width: 1440px) {  
  .carousel-images {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
  
  .carousel-image {
    transition: opacity 0.3s ease-in-out;
  }
  
  .slide-left {
    transform: translateX(-100%);
  }
  
  .slide-right {
    transform: translateX(100%);
  }
  
  .transitioning {
    opacity: 0;
  }
  
  .carousel-images {
    transform: none !important;
  }

}
@media screen and (max-width: 430px) {
  .navigation {
    padding-bottom: 1em;
  }
  .nav-item {
    display: none;
  }
  .nav-container {
    justify-content: space-between;
  }
  .products-head {
    font-size: 3em;
  }
  .carousel-container {
    height: auto;
    min-height: 30rem;
  }
  .contact-head {
    text-align: center;
  }
  .products-div {
    margin: 0 5px 0 5px;
  }
  .logo-div {
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    z-index: -5;
    width: 100%;
    padding-top: 0.3rem;
  }
  .mobile-menu-toggle {
    display: block;
    background: none;
    border: none;
    color: #F03818;
    cursor: pointer;
    padding: 0 1rem;
  }

  .tiny-menu {
    position: absolute;
    top: 3em;
    left: 0;
    background-color: #761706;
    border-radius: 4px;
    padding: 0.5rem 0;
    font-family: "Maven Pro", serif;
  }

  .tiny-menu .tiny-nav-items {
    display: flex;
    flex-direction: column;
  }

  .tiny-menu .tiny-nav-item {
    padding: 1rem;
  }

  .tiny-menu a {
    color: white;
    text-decoration: none;
    font-size: 1em;
  }
  .hero-text-small {
    width: 100%;
  }
  .hero-text {
    font-size: 4em;
  }
}
@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
    height: 100vh;
  }
  .hero-left {
    width: 80%;
    margin-top: 5rem;
  }
  .hero-text {
    white-space: inherit;
    font-size: 3rem;
  }
  .hero-right {
    width: 100%;
    transform: none;
    justify-content: center;
  }
  .hero-right img {
    width: 80vw;
  }
  .products-div {
    margin: 0 5px 0 5px;
  }
  .mobile-menu-toggle {
    opacity: 0;
  }
  .mobile-menu {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .carousel-dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 1em;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    background-color: #f8fcff;
    border-radius: 50%;
    display: inline-block;
    margin: 0 7px;
  }
  
  .dot.active {
    background-color: #ffd30d;
  }

  .carousel-parent {
    min-height: 65vh;
  }
  .carousel-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: auto;
    margin: 0 auto 2em auto;
  }
  .carousel-image {
    box-shadow: none;
  }
  .carousel-image.active {
    opacity: 1;
  }
  .carousel-images {
    max-width: 95%;
    padding-top: 5em;
    padding-bottom: 1em;
  }
  .carousel-image img {
    object-fit: contain;
  }
  .carousel-image.center {
    width: 50vw;
    height: 50vw;
  }
  .carousel-text-info.ingredients {
    max-width: 90vw;
    margin-bottom: 0;
  }
}

.order-link-div {
  width: 3em;
  height: 2em;
  color: #091013;
}

html, body {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

@media screen and (max-width: 500px) {
  .carousel-parent {
    min-height: 100vh;
  }
}


@media screen and (max-width: 615px) {  
  .products-head {
    font-size: 3em;
  }
  .contact-head {
    font-size: 3em;
  }
  .products-text-div {
    width: 80vw;
  }
}