.order-div {
  background-color: #091013;
  display: flex;
  flex-direction: column;
  margin-top: 5em;
  margin-bottom: 10em;
  margin-left: 5vw;
  margin-right: 5vw;
  font-family: "Maven Pro", sans-serif;
}
.order-head-small {
  color: #F03818;
  font-family: "Maven Pro", sans-serif;
  font-size: 1.1rem;
  font-weight: 900;
}
.section-title {
  color: #F03818;
  font-family: "Maven Pro", sans-serif;
  font-size: 1.1rem;
  font-weight: 900;
  margin-top: 2rem;
}
.order-head {
  font-family: "Maven Pro", sans-serif;
  font-size: 4em;
  line-height: 1.5;
  color: white;
}
.order-subtitle {
  font-family: "Maven Pro", sans-serif;
  color: white;
  font-size: 1.1rem;
  line-height: 1.4;
  max-width: 50vw;
}

.order-form-div {
  width: 100%;
  margin-top: 3em;
}

.form-group {
  margin-bottom: 15px;
}

.name-group {
  width: 69%;
}
.email-group {
  width: 69%;
}
.phone-group {
  width: 69%;
  margin-bottom: 3em;
}
.shipping-group {
  margin-top: 1em;
}
.flavor-group {
  width: 35%;
}
.quantity-group {
  width: 65%;
  padding-left: 2em;
}
.quantity-group-inside {
  display: flex;
  width: 100%;
  align-items: center;
  color: rgba(255, 255, 255, 0.477);

  input[type="number"] {
    width: 70%;
  }

  span {
    margin-left: 1em;
  }
}
.expl {
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.477);;
}
.add-row, .remove-row {
  padding: 5px 10px;
  background-color: #091013;
  border: 1px solid rgba(255, 255, 255, 0.477);
  color: rgba(255, 255, 255, 0.477);
  font-style: "Maven Pro", sans-serif;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
}
.add-row {
  margin-bottom: 2em;
  padding: 10px 20px;
}
.add-row:hover {
  border-color: #F03818;
  color: #F03818;
}
.remove-row {
  background-color: #091013;
  color: rgba(255, 255, 255, 0.477);
  border: 1px solid rgba(255, 255, 255, 0.477);
  margin-left: 2em;
  font-size: 0.9em;
}
.remove-row:hover {
  border-color: #F03818;
  color: #F03818;
}
.row-form-group {
  display: flex;
  width: 100%;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-style: "Maven Pro", sans-serif;
  color: rgba(255, 255, 255, 0.477);
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="tel"],
select,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1em;
}

textarea {
  height: 100px;
  resize: vertical;
}

.submit-button {
  display: block;
  width: 30%;
  padding: 10px;
  margin-top: 3em;
  background-color: #F03818;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

.submit-button:hover {
  background-color: #691405;
}

.error-message {
  color: #F03818;
  font-size: 0.8em;
  display: block;
  margin-top: 4px;
}

.confirmation {
  background-color: #e6ffe6;
  border: 1px solid #4CAF50;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}
.alert {
  background-color: #f3e1dd;
  border: 1px solid #F03818;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}
.form-row {
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1024px) {  
  .order-form-div {
    width: 100%;
  }

  .name-group, .email-group, .phone-group {
    width: 90%;
  }

  .submit-button {
    width: 100%;
  }

  .form-row {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 615px) {  
  .name-group, .email-group, .phone-group {
    width: 100%;
  }
  .form-row {
    grid-template-columns: 1fr 1fr;
  }
  .order-head {
    font-size: 3em;
  }
  .order-subtitle {
    max-width: 80vw;
  }
}