.detail-route .navigation, .detail-route .copyright-div, .detail-route .order-link-div {
  display: none;
}

h3 {
  color: #333;
}
.back-button {
  margin: 20px 2em;
  padding: 8px 16px;
  background-color: #f3f4f6;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.back-button:hover {
  background-color: #e5e7eb;
}
.pw-container {
  color: white;
}

.table-switcher {
  height: 50px;
  margin-top: 10px;

  span {
    font-size: 1.2em;
    color: white;
    font-weight: 600;
    padding: 50px;
  }
  span:hover {
    cursor: pointer;
  }
}
.active-switcher {
  color: #091013 !important;
  background-color: white;
}

.customers-table {
  min-height: 90vh;
  background-color: white;
  padding-top: 2vh;
}
.customers-col {
  width: 33%;
}

.table-header {
  margin-left: 2em;
  margin-bottom: 3em;
}
.orders-table {
  min-height: 90vh;
  background-color: white;
  padding-top: 2vh;
}
.orders-table-head {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin-left: 5%;
}
.date {
  width: 20%;
}
.name {
  width: 30%;
}
.order {
  width: 30%;
}
.col-head {
  font-weight: bold;
}
.detail-link {
  color: black;
  text-decoration: none;
}

.line {
  height: 1px;
  width: 90%;
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.3);
}
.line-light {
  height: 1px;
  width: 90%;
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

.customer-div {
  margin-left: 5%;
  width: 90%;
  display: flex;
  margin-bottom: 1em;
  margin-top: 1em;
  align-items: center;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.to {
  line-height: 2em;
}
.string-filter {
  margin-left: 2em;
  margin-right: 2em;
}

.scrollable {
  width: 100%;
  overflow-x: 'auto';
  white-space: 'nowrap';
}

.order-container {
  background-color: white;
  display: flex;
  flex: 2 1 auto;
  min-height: 90vh;
}
.detail-container {
  background-color: white;
  padding: 2em;
  border-right: 1px solid rgb(181, 181, 181);
}
.customer-details-info {
  display: flex;
  flex-direction: row;
  margin-bottom: 2em;

  div {
    min-width: 15vw;
  }
}

.tasks-container {
  padding: 2em;
  width: 100%;
}
.package-types-div {
  display: flex;
  align-items: center;
  width: 100%;

  h3 {
    width: 30%;
  }

  div {
    width: 70%;
  }
}

.add-package-type-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.add-package-type-btn {
  padding: 8px 12px;
  background-color: #F03818;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.add-package-type-btn:hover {
  background-color: #651102;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 90%;
}

.modal-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: #333;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modal-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.modal-input:focus {
  outline: none;
  border-color: #F03818;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.modal-input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 8px;
}

.modal-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.modal-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.modal-button-cancel {
  background-color: transparent;
  border: none;
  color: #666;
}

.modal-button-cancel:hover:not(:disabled) {
  color: #333;
}

.modal-button-confirm {
  background-color: #F03818;
  border: none;
  color: white;
}

.modal-button-confirm:hover:not(:disabled) {
  background-color: #651102;
}

.modal-button-confirm:disabled {
  background-color: #ed7e6a;
}

/* Add Type Button Styles */
.add-type-button {
  margin-left: 16px;
  padding: 8px 12px;
  background-color: #F03818;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.add-type-button:hover {
  background-color: #651102;
}





.tasks-div {
  margin-top: 5em;
}
.task {
  margin: 2em;
  display: flex;
  align-items: center;

  span {
    width: 80%;
  }
}
.check {
  cursor: pointer;
  stroke-width: 7px;
}
.check-gray {
  color: #c1c1c1;
}
.check-gray:hover {
 color: #6bb575;
 transition: color 0.2s ease-in-out;
}
.check-green {
  color: #3ebf51;
}
.check-green:hover {
  color: #c1c1c1;
  transition: color 0.2s ease-in-out;
}

.confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 400px;
  max-width: 90%;
}

.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.confirmation-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
.confirmation-button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
.confirmation-button-cancel {
  background: #e5e7eb;
}
.confirmation-button-confirm {
  background: #F03818;
  color: white;
}
.confirmation-title {
  font-size: 1.2em;
  margin-bottom: 10px;
  font-weight: 600;
}

.status-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-icon {
  flex-shrink: 0;
  stroke-width: 7px;
  margin-right: 1em;
}

.status-icon.completed {
  color: #3ebf51;
}

.status-icon.in-progress {
  color: #bf913e;
}

.status-icon.not-started {
  color: #bf513e;
}

.status-label {
  font-size: 14px;
  color: #4b5563;
}

.customer-order-list-container {
  padding: 2em;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  gap: 40px;
  width: 100%;
}

.customer-order-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.customer-order-list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  p {
    color: #333;
    font-weight: 700;
    font-size: 1.17em;
  }
}

.customer-order-link {
  color: white;
  background-color: #0c384b;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 600;
  text-decoration: none;
}

.customer-order-item {
  margin-left: 20px;
}

.order-link-header-div {
  align-self: center;
}

.warning-text {
  font-size: 3em;
  color: red;
  font-weight: 600;
}