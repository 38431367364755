#modal-root {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.hero-modal-overlay {
  position: fixed;
  inset: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
}

.hero-modal-backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease-in-out;
}

.hero-modal-container {
  position: relative;
  z-index: 51;
  width: 100%;
  max-width: 1200px;
  max-height: 90vh;
  margin: 1rem;
  overflow-y: auto;
  background-color: white;
  border-radius: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #091013;
}

.hero-modal-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  line-height: 1;
  color: #666;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.hero-modal-close-button:hover {
  color: #333;
}

.hero-modal-content {
  width: 100%;
  height: 100%;
}

.hero-modal-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}

/* When modal is open */
body.hero-modal-open {
  overflow: hidden;
}

.content-wrapper {
  transition: filter 0.2s ease-in-out;
}

body.hero-modal-open .content-wrapper {
  filter: blur(4px);
}